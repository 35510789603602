
import { Vue } from "vue-class-component"
import {hideLoadingLayer, showLoadingLayer} from "@/services/Globals";
import {getInterventionTypesRequest, getProjectInterventionTypesRequest} from "@/services/InterventionService";

export default class InterventionsAllOrIndividual extends Vue {
	headline = "Kontakte einstellen"
	alert: any = {
		text: 'Wählen Sie eine Methode zur Einstellung Ihrer Kontakte',
		class: 'alert-info'
	}
	choice: any = {
		items: {
			'all' : { name: 'Alle Interventionen konfigurieren', view: 'ContactConfigProcess', icon: 'icon-cogs' },
			'individual' : { name: 'Nur eine Intervention konfigurieren', view: 'InterventionsIndividual', icon: 'icon-cog', items: [] }
		}
	}
	interventionTypeConf: any = {}
	
	get objectId() {
		return this.$route.params.id
	}
	
	
	nextStep(args: any) {
		const data: any = {}
		
		if ( args.type ) {
			data.type	= args.type
		}
		
		this.$router.push({ name: args.view, params: data })
	}
	
	async beforeMount() {
		showLoadingLayer()
		
		await getInterventionTypesRequest().then((res: any) => {
			console.log(res)
			this.interventionTypeConf = res.result
		})
		
		await getProjectInterventionTypesRequest(this.objectId).then((res: any) => {
			console.log(res)
			if (res.result && res.result.length > 0) {
				res.result.map((item: any) => {
					this.choice.items['individual'].items.push({
						name: this.interventionTypeConf[item].name,
						view: 'ContactConfigProcessIndividual',
						icon: this.interventionTypeConf[item].icon,
						type: item
					})
				})
			}
			
			hideLoadingLayer()
		})
	}
}
